<template>
  <fw-layout management mobile-ready :back-to="`/manage/courses/${courseKey}`" :notfound="!loading && !edition">
    <template #main-content>
      <fw-panel :title="`Edição ${edition?.title ?? ''}`" :subtitle="course?.title ?? ''" featured>
        <template #toolbar>
          <fw-menu-more append-to-body>
            <b-dropdown-item custom aria-role="menuitem" class="paddingless">
              <fw-button
                type="basic-action"
                size="sm"
                label="Editar"
                custom-class="w-full"
                @click.native="isEditionModalActive = true"
              >
                Editar título
              </fw-button>
              <fw-button
                v-if="canDelete"
                type="basic-action"
                size="sm"
                custom-class="w-full"
                label="Eliminar"
                @click.native="confirmDeleteEdition"
              >
                Eliminar
              </fw-button>
              <fw-button
                v-if="canPublish"
                type="basic-action"
                size="sm"
                custom-class="w-full"
                label="Publicar"
                @click.native="publishEdition"
              >
                Publicar
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </template>

        <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
          <b-tab-item label="Metadados" value="metadata">
            <PanelManageCourseEditionMetadata
              v-if="edition"
              :saving-data="loading"
              :can-edit="true"
              :edition="edition"
              @save="updateEdition"
              @close="closeModal"
            />
          </b-tab-item>
          <b-tab-item :label="`Certificados (${enrollmentsCounter})`" value="enrollments">
            <div class="flex-1 flex gap-2 items-center text-sm pl-3">
              <div v-if="selectedUsers.length" class="font-semibold text-primary opacity-80">
                {{ selectedUsers.length }} selecionado(s)
              </div>
              <div v-else class="font-medium text-gray-400">{{ enrollmentsCounter }} certificados carregados</div>
            </div>
            <div class="flex gap-2 items-center mt-1 pl-3">
              <div class="flex-1 -mb-1">
                <b-field>
                  <b-checkbox size="is-small" @input="toggleSelectAll">Selecionar todos</b-checkbox>
                </b-field>
              </div>
              <div class="flex items-center gap-2">
                <fw-button
                  v-if="canNotify && selectedUsers.length"
                  type="link"
                  @click.native="confirmNotify(selectedUsers)"
                >
                  Notificar <span class="text-xs opacity-50 ml-1">({{ selectedUsers.length }})</span>
                </fw-button>
                <fw-button v-if="selectedCertificates.length" type="link" @click.native="comfirmDeleteSelected()">
                  Eliminar <span class="text-xs opacity-50 ml-1">({{ selectedUsers.length }})</span>
                </fw-button>
                <fw-button
                  v-if="canNotify && $refs?.enrollmentsList?.hasUsersToNotify && !selectedUsers.length"
                  type="link"
                  @click.native="confirmNotify"
                >
                  Notificar utilizadores
                </fw-button>
                <fw-button type="link" @click.native="isEnrollmentModalActive = true">Adicionar</fw-button>
              </div>
            </div>
            <PanelManageCourseEditionEnrollments
              ref="enrollmentsList"
              :edition-key="editionKey"
              :course-key="courseKey"
              :selected-users="selectedCertificates"
              @enrollments-count="enrollmentsCounter = $event"
              @delete="comfirmDelete"
              @selected="selectUser"
            />
          </b-tab-item>
        </b-tabs>
      </fw-panel>
    </template>

    <template #modals>
      <fw-modal
        :active.sync="isEnrollmentModalActive"
        :can-cancel="false"
        size="min"
        width="44rem"
        boxed="sm"
        @close="closeModal"
      >
        <ModalManageCourseEditionEnrollmentMetadata
          :saving-data="loading"
          :can-edit="true"
          :edition-key="editionKey"
          :course-key="courseKey"
          @close="closeModal"
          @added="addedEnrollments"
        />
      </fw-modal>

      <fw-modal :active.sync="isEditionModalActive" :can-cancel="true" size="auto" boxed="sm" @close="closeModal">
        <ModalManageCourseEditionMetadata
          :saving-data="loading"
          :can-edit="true"
          :edition="edition"
          @save="updateEdition"
          @close="closeModal"
        />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageCourseEditionEnrollments from '@/components/panels/manage/PanelManageCourseEditionEnrollments'
import ModalManageCourseEditionEnrollmentMetadata from '@/components/modals/ModalManageCourseEditionEnrollmentMetadata'
import ModalManageCourseEditionMetadata from '@/components/modals/ModalManageCourseEditionMetadata'
import PanelManageCourseEditionMetadata from '@/components/panels/manage/PanelManageCourseEditionMetadata'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PanelManageCourseEditionEnrollments,
    ModalManageCourseEditionEnrollmentMetadata,
    PanelManageCourseEditionMetadata,
    ModalManageCourseEditionMetadata,
  },

  data() {
    return {
      isEnrollmentModalActive: false,
      isEditionModalActive: false,
      loading: true,
      edition: null,
      course: null,
      selectedCertificates: [],
      selectedUsers: [],
      activeTab: 'metadata',
      enrollmentsCounter: 0,
      validations: {
        can_delete: false,
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    courseKey() {
      return this.$route.params.key
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    permissions() {
      return this.$store.getters.userPermissions
    },

    canNotify() {
      return Boolean(this.edition?.published_at)
    },

    canPublish() {
      return !this.edition?.published_at
    },

    canDelete() {
      return this.enrollmentsCounter === 0
    },
  },

  mounted() {
    this.getEdition()
  },

  methods: {
    getTabData() {
      //
    },

    toggleSelectAll(val) {
      console.log('toggleSelectAll', val)

      const selectedUsers = []
      const selectedCertificates = []
      if (val) {
        for (const enrollment of this.$refs.enrollmentsList.enrollments) {
          selectedUsers.push(enrollment.user_key)
          selectedCertificates.push(enrollment.key)
        }
      }

      this.selectedUsers = selectedUsers
      this.selectedCertificates = selectedCertificates
    },

    async getEdition() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerEdition(this.courseKey, this.editionKey)
        console.log('getManagerEdition :>> ', response)
        this.edition = response.edition
        this.course = response.course
        this.validations = response.validations
      })

      this.loading = false
    },

    async updateEdition(data) {
      this.loading = true

      console.log('data', data)
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.editManagerEdition(this.courseKey, data.key, data)
        console.log('editManagerEdition :>> ', response)
        this.edition = response.edition
        this.validations = response.validations
        this.isEditionModalActive = false
      })
      this.loading = false
    },

    confirmDeleteEdition() {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar curso',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>eliminar</strong> esta edição?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteEdition(this.edition.key)
        },
      })
    },

    async deleteEdition(key) {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteManagerEdition(this.courseKey, key)
        console.log('deleteManagerEdition :>> ', response)
        this.$router.push({ name: 'manage-course-editions', params: { key: this.courseKey } })
      })
      this.loading = false
    },

    publishEdition() {
      this.$buefy.dialog.confirm({
        confirmText: 'Publicar',
        type: 'is-primary',
        cancelText: 'Cancelar',
        title: 'Publicar edição',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>publicar</strong> este edição?</div>`,
        onConfirm: () => {
          this.updateEdition({ key: this.edition.key, publish: true })
        },
      })
    },

    closeModal() {
      this.isEnrollmentModalActive = false
      this.isEditionModalActive = false
    },

    comfirmDeleteSelected() {
      console.log('this.selectedCertificates', this.selectedCertificates)
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar certificados',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>eliminar</strong> certificados selecionados?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteEnrollment(this.selectedCertificates)
        },
      })
    },

    comfirmDelete(key) {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar certificado',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja <strong>eliminar</strong> este certificado?
        Todos os dados serão eliminados e não será possível recuperar qualquer informação.</div>`,
        onConfirm: () => {
          this.deleteEnrollment([key])
        },
      })
    },

    async deleteEnrollment(keys) {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteEnrollments(this.courseKey, this.editionKey, keys)
        console.log('deleteManagerEdition :>> ', response)
        this.$refs.enrollmentsList.deleteEnrollments(keys)
        this.selectedCertificates = []
        this.selectedUsers = []
      })
      this.loading = false
    },

    confirmNotify(keys = null) {
      var msg = 'Tem a certeza que deseja <strong>notificar</strong> os trabalhadores selecionados?'
      if (keys) {
        msg = 'Tem a certeza que deseja <strong>notificar</strong> os trabalhadores ainda não notificados?'
      }

      this.$buefy.dialog.confirm({
        confirmText: 'Notificar utilizadores',
        type: 'is-primary',
        cancelText: 'Cancelar',
        title: 'Enviar notificações',
        message: msg,
        onConfirm: () => {
          this.notifyUsers(keys)
        },
      })
    },

    async notifyUsers(keys = null) {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.notifyEnrollmentsUsers(this.courseKey, this.editionKey, keys)
        console.log('notifyEnrollmentsUsers :>> ', response)
        this.$refs.enrollmentsList.getEnrollments()
        this.selectedCertificates = []
        this.selectedUsers = []
      })
      this.loading = false
    },

    addedEnrollments() {
      console.log('added')
      this.$refs.enrollmentsList.getEnrollments()
    },

    selectUser(userKey, enrollmentKey, select) {
      console.log({ userKey, enrollmentKey })
      if (select) {
        this.selectedCertificates.push(enrollmentKey)
      } else {
        let index = this.selectedCertificates.findIndex(el => el == enrollmentKey)
        if (index > -1) this.selectedCertificates.splice(index, 1)
      }

      if (select) {
        this.selectedUsers.push(userKey)
      } else {
        let index = this.selectedUsers.findIndex(el => el == userKey)
        if (index > -1) this.selectedUsers.splice(index, 1)
      }

      console.log('this.selectedUsers', this.selectedUsers)
      console.log('this.selectedCertificates', this.selectedCertificates)
    },
  },
}
</script>

<style>
.b-checkbox .check {
  border: solid 1px #6accb3 !important;
}
</style>
